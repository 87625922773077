var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TitleBar'),_c('b-container',[_c('b-row',{staticClass:"date-separator"},[_c('h3',[_vm._v("Today")])]),_c('b-row',{attrs:{"cols-lg":"2"}},_vm._l((_vm.shows.filter(
          // (s) => new Date(s.date).getTime() <= new Date().getTime() + 1000 * 60 * 60 * 24,
          (s) => _vm.happensToday(new Date(s.date)),
        )),function(show){return _c('div',{key:show.id},[_c('b-col',[_c('ShowItem',{attrs:{"show":show}})],1)],1)}),0),_c('b-row',{staticClass:"date-separator"},[_c('b-col',[_c('h3',[_vm._v("This week")])])],1),_c('b-row',{attrs:{"cols-lg":"2"}},_vm._l((_vm.shows.filter(
          (s) =>
            !_vm.happensToday(new Date(s.date)) &&
            new Date(s.date).getTime() <= new Date().getTime() + 1000 * 60 * 60 * 24 * 7,
        )),function(show){return _c('div',{key:show.id},[_c('b-col',[_c('ShowItem',{attrs:{"show":show}})],1)],1)}),0),_c('b-row',{staticClass:"date-separator"},[_c('b-col',[_c('h3',[_vm._v("Upcoming")])])],1),_c('b-row',{attrs:{"cols-lg":"2"}},_vm._l((_vm.shows.filter(
          (s) => new Date(s.date).getTime() > new Date().getTime() + 1000 * 60 * 60 * 24 * 7,
        )),function(show){return _c('div',{key:show.id},[_c('b-col',[_c('ShowItem',{attrs:{"show":show}})],1)],1)}),0),_c('b-row',[_c('b-col',[_c('b-alert',{attrs:{"id":"error-alert","show":_vm.showError,"variant":"secondary"}},[_c('h6',{staticClass:"alert-heading fw-bold"},[_vm._v("Error while refreshing data")]),_c('p',[_vm._v("If you have cached data on your device, it will be used instead.")]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.errorMsg))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }